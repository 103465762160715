import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
} from "reactstrap";
import {
    CREATE_MEETING_ROUTE,
    GET_ALL_GROUPS_URL,
    GET_ALL_MEETINGS_URL,
    ITEMS_PER_PAGE_LIST,
    LOGIN_ROUTE,
    MEETING_SORT_BY_ENUM,
    SORT_ORDER_ENUM,
} from "../../constants";
import { getLocalTimeFromUTC } from "../utils/timeConverts";
import ProjectNavbar from "../widgets/projectNavbar";
import ProjectTable from "../widgets/projectTable";
import TextField from "../widgets/textField";
import {
    addAllMeetings,
    removeAllMeetings,
} from "./../../redux/meeting/meetingActions";

const Meetings = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const currentUser = useSelector((state) => state.authReducer.currentUser);

    const meetings = useSelector((state) => state.meetingReducer.meetings);
    const dispatch = useDispatch();

    const [groups, setGroups] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortBy, setSortBy] = useState(MEETING_SORT_BY_ENUM[0]);
    const [sortOrder, setSortOrder] = useState(SORT_ORDER_ENUM[0]);
    const [meetingName, setMeetingName] = useState("");
    const [meetingTitle, setMeetingTitle] = useState("");
    const [searchUrl, setSearchUrl] = useState(
        GET_ALL_MEETINGS_URL +
        `?per_page=${perPage}&page=${pageNumber}&order=${sortBy},${sortOrder}`
    );

    const LOCAL_START_DATE = "Start Date";
    const LOCAL_END_DATE = "End Date";
    const LOCAL_SORT = "Sort By";
    const LOCAL_SORT_ORDER = "Sort Order";
    const LOCAL_MEETING_NAME = "Meeting Name";
    const LOCAL_MEETING_TITLE = "Meeting Title";

    function handleInputChange(e, type) {
        e.preventDefault();

        if (type === LOCAL_START_DATE) setStartDate(e.target.value);
        if (type === LOCAL_END_DATE) setEndDate(e.target.value);
        if (type === LOCAL_SORT) setSortBy(e.target.value);
        if (type === LOCAL_SORT_ORDER) setSortOrder(e.target.value);
        if (type === LOCAL_MEETING_NAME) {
            setMeetingName(e.target.value);
            setMeetingTitle("");
        }
        if (type === LOCAL_MEETING_TITLE) {
            setMeetingTitle(e.target.value);
            setMeetingName("");
        }
    }

    const updateSearchUrl = (e) => {
        let queryParameters = `?per_page=${perPage}&page=${pageNumber}&order=${sortBy},${sortOrder}`;

        if (meetingName !== "")
            queryParameters += `&search=name,${meetingName}`;
        else if (meetingTitle !== "")
            queryParameters += `&search=title,${meetingTitle}`;
        if (startDate) queryParameters += `&start_date=${startDate}`;
        if (endDate) queryParameters += `&end_date=${endDate}`;

        setSearchUrl(GET_ALL_MEETINGS_URL + queryParameters);
    };

    async function fetchMeetings() {
        const response = await axios(searchUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        let meetingsList = [];
        response.data.meetings.data.forEach((meeting) => {
            meeting.start_time = getLocalTimeFromUTC(
                `${meeting.start_time} +0000`,
                currentUser.current_client_timezone
            );
            meeting.end_time = getLocalTimeFromUTC(
                `${meeting.end_time} +0000`,
                currentUser.current_client_timezone
            );
            meetingsList.push(meeting);
        });
        setTotalItems(response.data.meetings.total);

        dispatch(addAllMeetings(meetingsList));
    }

    const fetchGroups = async () => {
        const response = await axios(GET_ALL_GROUPS_URL + `?per_page=5`, {
            headers: { Authorization: `Bearer ${jwt}` },
        });

        setGroups(response.data.groups.data);
    };

    useEffect(() => {
        updateSearchUrl();
        fetchMeetings();
        fetchGroups();
        return () => {
            dispatch(removeAllMeetings());
        };
    }, [dispatch, perPage, pageNumber, searchUrl]);

    function handleItemsPerPageChange(e, newPerPage) {
        e.preventDefault();

        setPerPage(newPerPage);
        setPageNumber(1);
    }

    var paginationLinks = [];
    for (var i = 1; i <= Math.ceil(totalItems / perPage); i++) {
        paginationLinks.push(i);
    }

    return (
        <>
            {jwt === null ? (
                // if the user is not logged in then redirect to login
                <Redirect to={LOGIN_ROUTE} />
            ) : (
                <div className="container-lg">
                    <ProjectNavbar />
                    <div className="container-fluid">
                        <div className="page-header">
                            <h1 className="d-inline">Meetings</h1>
                            {currentUser.role !== "USER" ? (
                                <Link
                                    to={CREATE_MEETING_ROUTE}
                                    className={`btn btn-outline-success float-end ${groups.length < 1 ? "disabled" : ""
                                        } `}
                                    disabled-tooltip="this is disabled tooltip"
                                >
                                    Create Meeting
                                </Link>
                            ) : null}
                        </div>
                        <hr />
                        {groups.length < 1 ? (
                            <div className="d-flex justify-content-end text-danger">
                                *You need to have atleast 1 group to create a
                                meeting!
                            </div>
                        ) : null}
                        <Card>
                            <CardHeader>
                                <CardTitle>Filters</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setPerPage(ITEMS_PER_PAGE_LIST[0]);
                                        setPageNumber(1);
                                        updateSearchUrl(e);
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <FormGroup floating>
                                                <Input
                                                    id={LOCAL_START_DATE}
                                                    name={LOCAL_START_DATE}
                                                    type="date"
                                                    value={startDate ?? ""}
                                                    // className="d-inline"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            LOCAL_START_DATE
                                                        )
                                                    }
                                                />
                                                <Label
                                                    htmlFor={LOCAL_START_DATE}
                                                >
                                                    {LOCAL_START_DATE}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup floating>
                                                <Input
                                                    id={LOCAL_END_DATE}
                                                    name={LOCAL_END_DATE}
                                                    type="date"
                                                    value={endDate ?? ""}
                                                    className="d-inline"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            LOCAL_END_DATE
                                                        )
                                                    }
                                                />
                                                <Label htmlFor={LOCAL_END_DATE}>
                                                    {LOCAL_END_DATE}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup floating>
                                                <Input
                                                    id={LOCAL_SORT}
                                                    name={LOCAL_SORT}
                                                    type="select"
                                                    value={sortBy}
                                                    // className="d-inline"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            LOCAL_SORT
                                                        )
                                                    }
                                                >
                                                    {MEETING_SORT_BY_ENUM.map(
                                                        (sort, index) => {
                                                            return (
                                                                <option
                                                                    key={sort}
                                                                >
                                                                    {sort}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Input>
                                                <Label for={LOCAL_SORT}>
                                                    {LOCAL_SORT}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup floating>
                                                <Input
                                                    id={LOCAL_SORT_ORDER}
                                                    name={LOCAL_SORT_ORDER}
                                                    type="select"
                                                    value={sortOrder}
                                                    className="d-inline"
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            e,
                                                            LOCAL_SORT_ORDER
                                                        )
                                                    }
                                                >
                                                    {SORT_ORDER_ENUM.map(
                                                        (order, index) => {
                                                            return (
                                                                <option
                                                                    key={order}
                                                                >
                                                                    {order}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Input>
                                                <Label
                                                    htmlFor={LOCAL_SORT_ORDER}
                                                >
                                                    {LOCAL_SORT_ORDER}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextField
                                                label={LOCAL_MEETING_TITLE}
                                                placeholder={`Enter ${LOCAL_MEETING_TITLE}`}
                                                type="text"
                                                value={meetingTitle}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_MEETING_TITLE
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                label={LOCAL_MEETING_NAME}
                                                placeholder={`Enter ${LOCAL_MEETING_NAME}`}
                                                type="text"
                                                value={meetingName}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        e,
                                                        LOCAL_MEETING_NAME
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary">
                                        Search
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                        <div className="my-4">
                            Show
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                className="d-inline mx-2"
                            >
                                <DropdownToggle caret>{perPage}</DropdownToggle>
                                <DropdownMenu>
                                    {ITEMS_PER_PAGE_LIST.map((itemPerPage) => {
                                        return (
                                            <DropdownItem
                                                key={itemPerPage}
                                                onClick={(e) =>
                                                    handleItemsPerPageChange(
                                                        e,
                                                        itemPerPage
                                                    )
                                                }
                                            >
                                                {itemPerPage}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </Dropdown>
                            entries
                        </div>
                        <ProjectTable data={meetings} type="MEETINGS" />
                        <Pagination
                            aria-label="Page navigation example"
                            className="justify-content-center"
                        >
                            {paginationLinks.map((paginationLink) => (
                                <PaginationItem
                                    key={paginationLink}
                                    active={pageNumber === paginationLink}
                                >
                                    <PaginationLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (pageNumber !== paginationLink)
                                                setPageNumber(paginationLink);
                                        }}
                                    >
                                        {paginationLink}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                        </Pagination>
                    </div>
                </div>
            )}
        </>
    );
};

export default Meetings;
