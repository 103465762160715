import axios from "axios";
import {
    CREATE_MEETING_URL,
    MEETINGS_ROUTE,
    UPLOAD_MEETING_FILES_URL,
} from "../../constants";
import { getUTCDateForServer, getUTCDateTimeForServer } from "./timeConverts";

const handleCreateMeeting = async (
    e,
    jwt,
    clearSuccessAndErrorMessages,
    validateAll,
    history,
    name,
    title,
    date,
    topicNumbering,
    group,
    startTime,
    endTime,
    meeting,
    filesToBeUploaded,
    setSuccessMessage,
    setCreateMeetingError,
    setNameError
) => {
    e.preventDefault();

    clearSuccessAndErrorMessages();

    if (validateAll()) {
        let newMeetingObject = {};
        newMeetingObject["name"] = name.trim();
        newMeetingObject["title"] = title.trim();
        newMeetingObject["topic_numbering"] = topicNumbering;
        const year = date.split("-")[0];
        const month = date.split("-")[1];
        const day = date.split("-")[2];
        newMeetingObject["date"] = getUTCDateForServer(
            new Date(
                year,
                month - 1, // JavaScript counts months from 0 to 11
                day,
                startTime.split(":")[0],
                startTime.split(":")[1]
            )
        );
        newMeetingObject["group_id"] = group.id;
        newMeetingObject["start_time"] = getUTCDateTimeForServer(
            new Date(
                year,
                month - 1, // JavaScript counts months from 0 to 11
                day,
                startTime.split(":")[0],
                startTime.split(":")[1]
            )
        );
        newMeetingObject["end_time"] = getUTCDateTimeForServer(
            new Date(
                year,
                month - 1, // JavaScript counts months from 0 to 11
                day,
                endTime.split(":")[0],
                endTime.split(":")[1]
            )
        );

        // setting ids of all new meeting items to null
        meeting.items.forEach((item) => {
            item.id = null;
        });
        newMeetingObject["add_items"] = meeting.items;
        try {
            const response = await axios.post(
                CREATE_MEETING_URL,
                newMeetingObject,
                {
                    headers: { Authorization: `Bearer ${jwt}` },
                }
            );
            if (response.status === 201) {
                setSuccessMessage("Meeting created successfully");

                console.log(
                    `filesToBeUploaded = ${JSON.stringify(filesToBeUploaded)}`
                );
                filesToBeUploaded.forEach(async (file) => {
                    if (file.topic_id === null || file.topic_id === undefined) {
                        console.log(
                            `file.topic_id is null = ${JSON.stringify(file)}`
                        );

                        response.data.meeting.items.forEach((item) => {
                            if (item.name === file.topic.name) {
                                file.topic_id = item.id;
                                return;
                            }
                        });
                    }

                    console.log(
                        `response = ${JSON.stringify(response.data.meeting)}`
                    );
                    console.log(`file.topic_id = ${file.topic_id}`);

                    let form_data = new FormData();
                    form_data.append("topic_id", file.topic_id);
                    form_data.append("is_minute_file", file.is_minute_file);
                    form_data.append("files", file.file);
                    form_data.append("description", file.file_description);

                    const filesResponse = await axios.post(
                        UPLOAD_MEETING_FILES_URL,
                        form_data,
                        {
                            headers: {
                                "Content-type": "multipart/form-data",
                                Authorization: `Bearer ${jwt}`,
                            },
                        }
                    );

                    if (filesResponse.status === 200)
                        setSuccessMessage(
                            "Meeting files uploaded successfully"
                        );
                });
                history.push(MEETINGS_ROUTE);
            }
        } catch (error) {
            if (error.response) {
                setCreateMeetingError(error.response.data.error);
                if (error.response.data.errors)
                    if (error.response.data.errors.name)
                        setNameError(error.response.data.errors.name);
            }
        }
    }
};

export default handleCreateMeeting;
