import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button } from "reactstrap";
import {
    DOWNLOAD_MEETING_FILES_URL,
    DOWNLOAD_MEETING_PACKETS_URL,
    GET_PUBLIC_MEETING_BY_ID_URL,
} from "../../constants";
import axios from "axios";
import SunEditor from "suneditor-react";
import "./../../styles/viewMeeting.css";
import { getLocalTimeFromUTC } from "../utils/timeConverts";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const ViewPublicMeeting = () => {
    const jwt = useSelector((state) => state.authReducer.jwt);
    const { id } = useParams();
    const componentRef = useRef();

    const [meeting, setMeeting] = useState({
        name: null,
        title: null,
        date: null,
        start_time: null,
        end_time: null,
        items: [
            {
                description: null,
                order: null,
                is_active: null,
                minute: null,
                type: null,
            },
        ],
    });

    const location = useLocation()
    const {is_minutes} = location.state == null ? false : location.state;

    const fetchMeeting = async () => {
        const response = await axios.get(
            GET_PUBLIC_MEETING_BY_ID_URL.replace(":id", id)
        );
        const startTime = getLocalTimeFromUTC(
            `${response.data.meeting.start_time} +0000`,
            response.data.meeting.client_timezone
        );
        response.data.meeting.start_time = startTime.toTimeString();
        response.data.meeting.date = startTime.toDateString();
        response.data.meeting.end_time = getLocalTimeFromUTC(
            `${response.data.meeting.end_time} +0000`,
            response.data.meeting.client_timezone
        ).toTimeString();

        setMeeting(response.data.meeting);
    };

    useEffect(() => {
        fetchMeeting();
    }, []);

    return (
        <>
            <div className="container-lg">
                <div className="container-fluid">
                    <h2 className="mt-4">Meeting</h2>
                    <hr />
                    <ReactToPrint
                        trigger={() => (
                            <Button color="success" className="float-end">
                                Print / Download
                            </Button>
                        )}
                        content={() => componentRef.current}
                    />
                    {meeting.is_finalized ? 
                    <a
                    key={meeting.id}
                    href={DOWNLOAD_MEETING_PACKETS_URL.replace(
                        ":id",
                        meeting.id
                    )}
                    className="btn-info btn"
                    >
                        View Agenda Packet
                    </a> :
                    null
                }
                    <div ref={componentRef} className="p-3">
                        <h2>Meeting Agenda</h2>
                        <p>
                            <span className="fw-bold">Name: </span>{" "}
                            {meeting.name}
                        </p>
                        <p>
                            <span className="fw-bold">Title: </span>{" "}
                            {meeting.title}
                        </p>
                        <p>
                            <span className="fw-bold">Date: </span>{" "}
                            {meeting.date}
                        </p>
                        <p>
                            <span className="fw-bold">Start Time: </span>{" "}
                            {meeting.start_time}
                        </p>
                        <p>
                            <span className="fw-bold">End Time: </span>{" "}
                            {meeting.end_time}
                        </p>
                        <p>
                            <span className="fw-bold">Group: </span>{" "}
                            {meeting.group_name}
                        </p>
                        {/* agendas */}
                        <div className="mb-2">
                            <ol type={meeting.topic_numbering}>
                                {meeting.items.map((item, itemIndex) => {
                                    return item.type === "AGENDA" ? (
                                        /* agenda */
                                        <div
                                            key={`Agenda ${item.id}`}
                                            className="view-suneditor li-marker-bold"
                                        >
                                            <SunEditor
                                                defaultValue={item.description}
                                                setDefaultStyle="height: auto"
                                                setOptions={{
                                                    buttonList: [],
                                                }}
                                                disable={true}
                                            />
                                        </div>
                                    ) : (
                                        /* topic */
                                        <li
                                            key={`Topic ${item.id}`}
                                            className="view-suneditor li-marker-bold"
                                        >
                                            <span className="fw-bold">
                                                {item.name}
                                            </span>
                                            {item.description !== null ? (
                                                <SunEditor
                                                    defaultValue={
                                                        item.description
                                                    }
                                                    setDefaultStyle="height: auto"
                                                    setOptions={{
                                                        buttonList: [],
                                                    }}
                                                    disable={true}
                                                />
                                            ) : null}
                                            {item.minute && is_minutes ? (
                                                <div className="border p-2 w-100">
                                                    {item.minute}
                                                </div>
                                            ) : null}
                                            {!item.is_private_file && item.files && is_minutes
                                                ? item.files.map((file) => {
                                                      return (
                                                          <a
                                                              key={file.id}
                                                              href={DOWNLOAD_MEETING_FILES_URL.replace(
                                                                  ":id",
                                                                  file.id
                                                              )}
                                                              className="d-block"
                                                          >
                                                              <Button className="border-0 bg-success mt-2 mb-2">
                                                                  <FontAwesomeIcon
                                                                      icon={
                                                                          faDownload
                                                                      }
                                                                      size="sm"
                                                                      title="File Download"
                                                                      className="me-2"
                                                                  />
                                                                  {
                                                                      file.description
                                                                  }
                                                              </Button>
                                                          </a>
                                                      );
                                                  })
                                                : null}
                                            {!item.is_private_file &&
                                            item.minute_files
                                                ? item.minute_files.map(
                                                      (file) => {
                                                          return (
                                                              <a
                                                                  key={file.id}
                                                                  href={DOWNLOAD_MEETING_FILES_URL.replace(
                                                                      ":id",
                                                                      file.id
                                                                  )}
                                                              >
                                                                  <Button className="border-0 bg-success mt-2 mb-2">
                                                                      <FontAwesomeIcon
                                                                          icon={
                                                                              faDownload
                                                                          }
                                                                          size="sm"
                                                                          title="File Download"
                                                                          className="me-2"
                                                                      />
                                                                      {
                                                                          file.description
                                                                      }
                                                                  </Button>
                                                              </a>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </li>
                                    );
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewPublicMeeting;
